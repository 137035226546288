import React from "react"
import { Form, Button } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Zapisy from "../components/zapisy"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "zapisy" }}>
    <SEO title="Zapisy"/>
    <Zapisy/>
  </Layout>
)

export default SecondPage
