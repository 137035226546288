import React, { useState } from "react"
import { Button, ButtonGroup, Col, Container, Modal, Row, Alert } from "react-bootstrap"
import { TiArrowRightOutline } from "react-icons/ti"
import "./style.scss"
import { Link } from "gatsby"

const locations = [
  { key: 'o', label: 'Ochota' },
  { key: 'u', label: 'Ursynów' },
  // { key: 's', label: 'Śródmieście' }
];

export const scheduleData = {
  o: [
    { day: 'Poniedziałek', classes: [
        { title: 'Modern Jazz', subtitle: 'Profil Pro', shortDesc: ['Profil Pro', '14+ lat', 'Zespół turniejowy', '19:00-20:30'], fullDesc: ['Wiek: 14+ lat', 'Poziom: zaawansowany', 'Termin: poniedziałki 19:00-20:30 i czwartki 18:00-19:30', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska, Ewa Konecka'] }
      ]},
    { day: 'Wtorek', classes: [
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['7-9 lat', '16:20-17:20'], fullDesc: ['Wiek: 7-9 lat', 'Poziom: średniozaawansowany', 'Termin: wtorek 16:20 – 17:20', 'Lokalizacja: sala gimnastyczna CKU2 ul. Szczęśliwicka 50/54', 'Instruktor: Inga Kobyłecka, Aleksandra Emmel'] },
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['5-7 lat', '17:25-18:10'], fullDesc: ['Wiek: 5-7 lat', 'Poziom: podstawowy', 'Termin: wtorek 17:25 – 18:10', 'Lokalizacja: sala gimnastyczna CKU2 ul. Szczęśliwicka 50/54', 'Instruktor: Inga Kobyłecka, Aleksandra Emmel'] },
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['10-12 lat', '18:15-19:15'], fullDesc: ['Wiek: 10-12 lat', 'Poziom: podstawowy/średniozaawansowany', 'Termin: wtorek 18:15 – 19:15', 'Lokalizacja: sala gimnastyczna CKU2 ul. Szczęśliwicka 50/54', 'Instruktor: Krzysztof Ciszewski, Inga Kobyłecka'] },
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['12+ lat', '19:15-20:15'], fullDesc: ['Wiek: 12+ lat', 'Poziom: średniozaawansowany/zaawansowany', 'Termin: wtorek 19:15 – 20:15', 'Lokalizacja: sala gimnastyczna CKU2 ul. Szczęśliwicka 50/54', 'Instruktor: Krzysztof Ciszewski, Inga Kobyłecka'] }
      ]},
    { day: 'Środa', classes: [
        { title: 'Baby Dance', subtitle: '', shortDesc: ['3-6 lat', '16:30-17:15'], fullDesc: ['Wiek: 3-6 lat', 'Poziom: początkujący', 'Termin: środa 16:30 – 17:15', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska, Aleksandra Emmel'] },
        { title: 'Jazz', subtitle: 'Profil Mini', shortDesc: ['Profil Mini','7-9 lat', '17:20-18:20'], fullDesc: ['Wiek: 7-9 lat', 'Poziom: podstawowy', 'Termin: środa 17:20 – 18:20', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska, Aleksandra Emmel'] },
        { title: 'Modern Jazz', subtitle: 'Profil Junior', shortDesc: ['Profil Junior','10-12 lat', 'Grupa turniejowa', '18:25-19:25'], fullDesc: ['Wiek: 10-12 lat', 'Poziom: zaawansowany', 'Termin: środa 18:25 – 19:25 i czwartek 16:30 – 18:00', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska', 'Grupa turniejowa, do grupy obowiązuje casting'] }
      ]},
    { day: 'Czwartek', classes: [
        { title: 'Modern Jazz', subtitle: 'Profil Junior', shortDesc: ['Profil Junior','10-12 lat', 'Grupa turniejowa', '16:30-18:00'], fullDesc: ['Wiek: 10-12 lat', 'Poziom: zaawansowany', 'Termin: środa 18:25 – 19:25 i czwartek 16:30 – 18:00', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Ewa Konecka, Wiktoria Sobocińska', 'Grupa turniejowa, do grupy obowiązuje casting'] },
        { title: 'Jazz', subtitle: '', shortDesc: ['10-13 lat', '18:00-19:00'], fullDesc: ['Wiek: 10-13 lat', 'Termin: czwartek 18:00 – 19:00', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska'] },
        { title: 'Modern Jazz', subtitle: 'Profil Pro', shortDesc: ['Profil Pro','14+ lat', 'Zespół turniejowy', '19:00-20:30'], fullDesc: ['Wiek: 14+ lat', 'Poziom: zaawansowany', 'Termin: poniedziałki 19:00 – 20:30 i czwartki 19:00 – 20:30', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Ewa Konecka, Wiktoria Sobocińska', 'Grupa turniejowa'] },
        { title: 'Modern Jazz', subtitle: 'Profil Adult', shortDesc: ['Profil Adult', 'Dorośli', '20:30-21:30'], fullDesc: ['Poziom: open', 'Wiek: 21+ lat', 'Termin: czwartki 20:30 – 21:30', 'Lokalizacja: sala taneczna DK Rakowiec, ul. Wiślicka 8', 'Instruktor: Wiktoria Sobocińska'] }
      ]}
  ],
  u: [
    { day: 'Poniedziałek', classes: [
        { title: 'Jazz', subtitle: 'JazzFil Bambi', shortDesc: ['JazzFil Bambi', '6-8 lat', '15:45-16:30'], fullDesc: ['Wiek: 6-8 lat', 'Poziom: podstawowy', 'Termin: poniedziałek 15:45 – 16:30', 'Lokalizacja: sala widowiskowa DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Ewa Konecka, Aleksandra Emmel'] },
        { title: 'Baby Dance', subtitle: '', shortDesc: ['3-6 lat', '16:35-17:15'], fullDesc: ['Wiek: 3-6 lat', 'Poziom: podstawowy', 'Termin: poniedziałek 16:35 – 17:15', 'Lokalizacja: sala widowiskowa DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Ewa Konecka, Aleksandra Emmel'] },
        { title: 'Jazz', subtitle: 'JazzFil Kids', shortDesc: ['JazzFil Kids', '9-12 lat', '17:15-18:15'], fullDesc: ['Wiek: 9-12 lat', 'Poziom: podstawowy', 'Termin: poniedziałek 17:15 – 18:15', 'Lokalizacja: sala widowiskowa DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Ewa Konecka', 'grupa ma rozszerzenie w środy - Jazzfil Kids+'] },
        { title: 'Modern Jazz', subtitle: 'JazzFil+', shortDesc: ['JazzFil+', '12-16 lat', 'Grupa turniejowa', '18:20-19:20'], fullDesc: ['Wiek: 12-16 lat', 'Poziom: zaawansowany', 'Termin: poniedziałek 18:20 – 19:20 i środa 17:30 – 19:00', 'Lokalizacja: sala widowiskowa DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Ewa Konecka', 'Grupa turniejowa, do grupy obowiązuje casting'] }
      ]},
    { day: 'Wtorek', classes: [
        { title: 'Musical Dance', subtitle: '', shortDesc: ['7-10 lat', '15:45-16:45'], fullDesc: ['Wiek: 7-10 lat', 'Termin: wtorek 15:45 – 16:45', 'Lokalizacja: sala taneczna DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Antonina Grupińska'] },
        { title: 'Jazz', subtitle: '', shortDesc: ['5-7 lat', '17:10-17:55 (od II semestru)'], fullDesc: ['Wiek: 5-7 lat', 'Termin: wtorek 17:10 – 17:55 (od II semestru)', 'zajęcia od II semestru', 'Lokalizacja: sala taneczna DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Antonina Grupińska'] },
        { title: 'Broadway for Ladies', subtitle: '', shortDesc: ['Dorośli', '18:00-19:00 (od II semestru)'], fullDesc: ['Poziom: open', 'Termin: wtorek 18:00 – 19:00 (od II semestru)',  'zajęcia od II semestru','Lokalizacja: sala taneczna DOK Ursynów, ul. Kajakowa 12b', 'Instruktor: Antonina Grupińska'] }
      ]},
    { day: 'Środa', classes: [
        { title: 'Jazz', subtitle: 'JazzFil Mini', shortDesc: ['JazzFil Mini', '7-9 lat', '15:30-16:30'], fullDesc: ['Wiek: 7-9 lat', 'Termin: środa 15:30 – 16:30', 'Lokalizacja: sala taneczna SP100, ul. Taneczna 54/58', 'Instruktor: Dominika Blezien'] },
        { title: 'Jazz', subtitle: 'JazzFil Kids+', shortDesc: ['JazzFil Kids+', '10-12 lat', '16:30-17:30'], fullDesc: ['Wiek: 10-12 lat', 'Poziom: średniozaawansowany', 'Termin: poniedziałek 17:15 – 18:15 i środa 16:30 – 17:30', 'Lokalizacja: sala taneczna SP100, ul. Taneczna 54/58', 'Instruktor: Dominika Blezien', 'grupa jest rozszerzeniem poniedziałkowej grupy Jazzfil Kids'] },
        { title: 'Modern Jazz', subtitle: 'JazzFil+', shortDesc: ['JazzFil+', '12-16 lat', 'Grupa turniejowa', '17:30-19:00'], fullDesc: ['Wiek: 12-16 lat', 'Poziom: zaawansowany', 'Termin: poniedziałek 18:15 – 19:25 i środa 17:30 – 19:00', 'Lokalizacja: sala taneczna SP100, ul. Taneczna 54/58', 'Instruktor: Ewa Konecka', 'Grupa turniejowa, do grupy obowiązuje casting'] }
      ]},
    { day: 'Piątek', classes: [
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['7-9 lat', '16:00-17:00'], fullDesc: ['Wiek: 7-9 lat', 'Termin: piątek 16:00 – 17:00', 'Lokalizacja: sala gimnastyczna SP 384 ul. Kajakowa 10', 'Instruktor: Ewa Konecka, Aleksandra Emmel'] },
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['5-7 lat', '17:05-17:50'], fullDesc: ['Wiek: 5-7 lat', 'Termin: piątek 17:05 – 17:50', 'Lokalizacja: sala gimnastyczna SP 384 ul. Kajakowa 10', 'Instruktor: Ewa Konecka, Aleksandra Emmel'] },
        { title: 'AkroGim', subtitle: 'Zajęcia akrobatyczno - gimnastyczne', shortDesc: ['10+ lat', '17:50-18:50'], fullDesc: ['Wiek: 10+ lat', 'Termin: piątek 17:50 – 18:50', 'Lokalizacja: sala gimnastyczna SP 384 ul. Kajakowa 10', 'Instruktor: Ewa Konecka, Aleksandra Emmel'] }
      ]}
  ],
  // s: [
  //   { day: 'Niedziela', classes: [
  //       { title: 'Lekcje Indywidualne', subtitle: '', shortDesc: ['Poziom: wszystkie', '16:30-17:30'], fullDesc: ['Poziom: wszystkie', 'Termin: niedziela 16:30 – 17:30', 'Lokalizacja: ul. Marszałkowska 115 (Viva Cuba)', 'Instruktor: Ewa Konecka'] },
  //       { title: 'Lekcje Indywidualne', subtitle: '', shortDesc: ['Poziom: wszystkie', '17:30-18:30'], fullDesc: ['Poziom: wszystkie', 'Termin: niedziela 17:30 – 18:30', 'Lokalizacja: ul. Marszałkowska 115 (Viva Cuba)', 'Instruktor: Ewa Konecka'] },
  //       { title: 'Warsztaty Taneczne', subtitle: '', shortDesc: ['raz w miesiącu', '18:30-20:00'], fullDesc: ['Wiek: 13+ lat', 'Poziom: średniozaawansowany/zaawansowany', 'Termin: raz w miesiącu niedziela 18:30 – 20:00', 'Lokalizacja: ul. Marszałkowska 115 (Viva Cuba)', 'Szczegółowe informacje w wydarzeniach na Facebooku'] },
  //       { title: 'Modern Jazz', subtitle: 'Profil Oldschool', shortDesc: ['21+ lat', '18:30-20:30'], fullDesc: ['Wiek: 21+ lat', 'Poziom: zaawansowany', 'Termin: niedziela 18:30 – 20:30', 'Lokalizacja: ul. Marszałkowska 115', 'Grupa zamknięta!'] }
  //     ]}
  // ]
};


const Zajecia = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <>
      <Col className={"zajeciaCard"} onClick={() => {
        setModalShow(true)
      }}>
        <p><b>{props.title}</b></p>
        {
          props.shortDesc.map((val) => (<p>{val}</p>))
        }
      </Col>
      <ZajeciaModal show={modalShow} onHide={() => setModalShow(false)} {...props}/>
    </>
  )
}
const ZajeciaWiersz = (props) => {
  return (
    <Row>
      {props.desc.map((val) => (
          <Zajecia {...val}/>
        ),
      )}
    </Row>
  )
}

const DzienTygWiersz = (props) => (
  <Row><Col className="grafikHeader"><h5>{props.children}</h5></Col></Row>
)
function ZajeciaModal(props) {
  return (
    <Modal{...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={"modalZajecia"}
    >
      <Modal.Header className={""} closeButton>
        <div>
          <h4>{props.title}</h4>
          {props.subtitle}
        </div>
      </Modal.Header>
      <Modal.Body>
        {
          props.fullDesc.map((val) => (<p className={"text-justify"}>{val}</p>))
        }
      </Modal.Body>
      <Modal.Footer>
        <Link to="/oferta" className="link-no-style">
          <Button onClick={props.onHide}>Opis zajęć</Button>
        </Link>
        <Link to="/zapisy" className="link-no-style">
          <Button onClick={props.onHide}>Zapisz się!</Button>
        </Link>
      </Modal.Footer>
    </Modal>
  )
}


const DaySchedule = ({ day, classes }) => (
  <Col sm={3}>
    <DzienTygWiersz>{day}</DzienTygWiersz>
    {classes.map((cls, index) => (
      <ZajeciaWiersz key={index} desc={[cls]} />
    ))}
  </Col>
);

const LocationSchedule = ({ location }) => (
  <Row>
    {scheduleData[location].map((daySchedule, index) => (
      <DaySchedule key={index} {...daySchedule} />
    ))}
  </Row>
);

const Grafik = (props) => {
  const [lokalizacja, setLokalizacja] = useState('');

  return (
    <>
      <Container className="text-center">
        <h1 className="text-center">Grafik 2024/25</h1>
        <p className="text-center">Wybierz lokalizację zajęć:</p>
        <ButtonGroup aria-label="Basic example" className="grafik_button">
          {locations.map(loc => (
            <Button
              key={loc.key}
              style={{ minWidth: "170px" }}
              size="lg"
              variant={lokalizacja === loc.key ? "violet" : "outline-violet"}
              onClick={() => setLokalizacja(loc.key)}
            >
              {loc.label}
            </Button>
          ))}
        </ButtonGroup>
        {lokalizacja && <LocationSchedule location={lokalizacja} />}
        <div className="pt-5">
          <Link to="/cennik" className="link-no-style">
            <Button className="cennikbutton" variant="lavender" size="lg">
              Zapoznaj się z cennikiem zajęć <TiArrowRightOutline size="1.2em" />
            </Button>
          </Link>
        </div>
        <p className="pt-5 text-center">
          Dni wolne w sezonie 2024/2025: <br />
          01.11, 11.11, PRZERWA ŚWIĄTECZNA (23.12- 06.01), FERIE ZIMOWE (03.02-14.02), PRZERWA ŚWIĄTECZNA (17.04-22.04), MAJÓWKA (01.05-03.05), BOŻE CIAŁO (19.06-22.06), WAKACJE
        </p>
      </Container>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1ew6H_KUPlLFr5CRz0iqyfoQ0NYSUrNs"
        width="100%"
        height="480"
      ></iframe>
    </>
  );
};
export default Grafik;