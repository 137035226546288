import { Button, Row, Col, Container, Form, Toast, Alert } from "react-bootstrap"
import Select from "react-select"
import React, { useState } from "react"
import Klauza from "../../files/KLAUZULA-INFORMACYJNA-RODO.pdf"
import Regulamin from "../../files/Regulamin-Zajęć.pdf"
import { MdMail, MdPhone } from "react-icons/md"
import { AiFillBank } from "react-icons/ai"
import { FaMapMarker } from "react-icons/fa"
import axios from "axios"
import { scheduleData } from "../grafik"


const generateOptions = (scheduleData) => {
  let options = [];
  let aggregatedOptions = {};

  for (const location in scheduleData) {
    if (location === 's') continue;
    const isHourLineString = x => x.includes(':') && x.includes('-');
    const filterOutHoursLine = desc => desc.filter(x => !isHourLineString(x));
    const getHoursLine = desc => desc.find(isHourLineString);

    scheduleData[location].forEach(daySchedule => {
      daySchedule.classes.forEach(cls => {
        const baseLabel = `${cls.title} ${filterOutHoursLine(cls.shortDesc).reverse().join(" - ")}`;
        const locationName = location === "o" ? "Ochota" : location === "u" ? "Ursynów" : "Śródmieście";
        const fullLabel = `${baseLabel} - ${locationName}`;

        if (!aggregatedOptions[fullLabel]) {
          aggregatedOptions[fullLabel] = {
            label: fullLabel,
            value: fullLabel,
            sortBy: `${cls.title} ${location}`,
            daysAndHours: []
          };
        }
        aggregatedOptions[fullLabel].daysAndHours.push(`${daySchedule.day} ${getHoursLine(cls.shortDesc)}`);
      });
    });
  }

// Convert aggregated results into an array and sort
  options = Object.values(aggregatedOptions).map(option => ({
    ...option,
    label: `${option.label} (${option.daysAndHours.join(', ')})`,
    value: `${option.value} (${option.daysAndHours.join(', ')})`
  })).sort((x, y) => (x.sortBy + x.label).localeCompare(y.sortBy + y.label));

  options.push(...(["Lekcje indywidualne",
    "Pierwszy taniec",
    "Taneczny Wieczór Panieński",
    // "Dance intensives",
    // "Roztańczony weekend z profildance",
    // "Zimowe warsztaty dla dzieci w ferie (22-26 stycznia 2024)",
    // "Letnie warsztaty dla dzieci w wakacje (19-23 sierpnia 2024)",
    // "Wakacyjny obóz taneczno-akrobatyczny w Chmielnie (14-27 lipca 2024)",
  ].map(o => ({
    label: o,
    value: o,
    sortBy: o,
  }))))

  return options
}

const options = generateOptions(scheduleData)


export default function Zapisy(props) {
  const [isOpiekun, setOpiekun] = useState(true)
  const [selectedOption, setSelectedOption] = useState(null)
  const [showA, setShowA] = useState(false)
  const hideShowA = () => {
    setShowA(false)
  }
  let mySubmitHandler = (event) => {
    let form = {
      _1_imie: event.target._1_imie.value,
      _2_nazwisko: event.target._2_nazwisko.value,
      _3_data_urodzenia: event.target._3_data_urodzenia.value,
      _4_wybrane_zajecia: selectedOption.map(o => o.label).join(", "),
      // _5_opiekun_check: event.target._5_opiekun_check.value,
      _5_1_imie_opiekun: event.target._5_1_imie_opiekun.value,
      _5_2_nazwisko_opiekun: event.target._5_2_nazwisko_opiekun.value,
      _6_email: event.target._6_email.value,
      _7_telefon: event.target._7_telefon.value,
      _8_wiadomosc: event.target._8_wiadomosc.value,
      _9_typ_formularza: 'zapis'
    }
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key] !== "") {
        formData.append(key, form[key])
      }
    })
    const x = axios.post("https://script.google.com/macros/s/AKfycbxI2WlzQakBRVahAbABrari802keNhwPikrDbprtV1kSnPjODUrvV6j6xAPfssYi2dG/exec", formData)
    event.preventDefault()
    x.then(x=>console.log((x)))
    event.target.reset()
    setShowA(true)
    setTimeout(() => {
      setShowA(false)
    }, 10000)
    return false
  }

  return (


    <Container>
      <Row className={"center"}>
        <Col md={{ span: 6, offset: 3 }}>
          <h3 className={"text-center mt-3 mb-4"}>FORMULARZ ZGŁOSZENIOWY</h3>
          <Form className={"gform"} method={"POST"}
                action={"https://script.google.com/macros/s/AKfycbxI2WlzQakBRVahAbABrari802keNhwPikrDbprtV1kSnPjODUrvV6j6xAPfssYi2dG/exec"}
                onSubmit={mySubmitHandler}
          >
            <Toast className={"mx-auto"} style={{ display: showA ? "block" : "none" }} onClose={hideShowA}>
              <Toast.Header>
                <strong className="mr-auto">profildancecompany</strong>
              </Toast.Header>
              <Toast.Body>Zapis został wysłany!</Toast.Body>
            </Toast>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Imię</Form.Label>
                <Form.Control
                  required
                  name="_1_imie"
                  type="text"
                  placeholder="Imię"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Nazwisko</Form.Label>
                <Form.Control
                  required
                  name="_2_nazwisko"
                  type="text"
                  placeholder="Nazwisko"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Data urodzenia</Form.Label>
                <Form.Control
                  required
                  name="_3_data_urodzenia"
                  type="date"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="exampleForm.ControlSelect1"
            >
              <Form.Label>Wybrane zajęcia</Form.Label>
              <Select
                isMulti
                placeholder={"Możesz wybrać wiele zajęć..."}
                as={"select"}
                name={"_4_wybrane_zajecia"}
                onChange={setSelectedOption}
                options={options}
              />
            </Form.Group>

            <Form.Group>
              <Form.Check
                label="Kontakt przez rodzica/opiekuna."
                checked={isOpiekun}
                name={"_5_opiekun_check"}
                onChange={() => {
                  setOpiekun(!isOpiekun)
                }}
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Imie opiekuna</Form.Label>
                <Form.Control
                  disabled={!isOpiekun}
                  required={isOpiekun}
                  name="_5_1_imie_opiekun"
                  type="text"
                  placeholder="Imie opiekuna"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Nazwisko opiekuna</Form.Label>
                <Form.Control
                  disabled={!isOpiekun}
                  required={isOpiekun}
                  name="_5_2_nazwisko_opiekun"
                  type="text"
                  placeholder="Nazwisko opiekuna"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>E-mail kontaktowy</Form.Label>
              <Form.Control required name={"_6_email"} type="email" placeholder="przykladowy@email.com"/>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Numer telefonu kontaktowy</Form.Label>
              <Form.Control required name={"_7_telefon"} type="tel" placeholder=""/>
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Dodatkowe informacje, pytania?</Form.Label>
              <Form.Control name={"_8_wiadomosc"} as="textarea" rows="3"/>
            </Form.Group>

            <Form.Group>
              <Form.Check
                style={{ float: "left" }}
                required
                feedback="Pole wymagane."
              />
              <div>Zapoznałem się i akceptuję <b><a href={Regulamin}>treść regulaminu</a></b>, <b><a href={Klauza}>klauzuli
                informacyjnej RODO</a></b></div>
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type="submit">
                Zapisz się!
              </Button>
            </div>
            <Toast className={"mx-auto mt-4"} style={{ display: showA ? "block" : "none" }} onClose={hideShowA}>
              <Toast.Header>
                <strong className="mr-auto">profildancecompany</strong>
                {/*<small>-></small>*/}
              </Toast.Header>
              <Toast.Body>Zapis został wysłany!</Toast.Body>
            </Toast>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
